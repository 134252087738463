@import '../../styles/breakpoints.scss';

.archive{

    &__header-wrapper{
        position: sticky;
        top: 0;
        width: 100%;
        text-align: center;
        padding: 15px 0 30px 0;
        overflow-y: visible;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 100%
          );
    }
    &__projects-wrapper{
        display: block;
        width: 100%;
        padding: 30px 0 90px 0;
        transition: opacity 0.4s ease-in-out;
        pointer-events: all;
        &.disabled{
            opacity: 0;
            pointer-events: none;
        }
    }

    &__project-wrapper{
        display: block;
        width: calc(100% - 60px);
        margin: 0 30px 5px 30px;
    }
    &__project-category{
        margin-right: 5px;
    }

    &__media-wrapper{
        position: fixed;
        top: 0;
        right: 0;
        width: 300px;
        height: 200px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__media{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        margin: 0 auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        transition: opacity 0.2s ease-in-out;
        box-shadow: 5px 5px 50px #000;
        background-color: rgba(0, 0, 0, 0.5);
        &.active{
            opacity: 1;
        }
    }

    &__filter-button{
        overflow: visible;
        position: relative;
        height: 3vw;
    }
}

@include mobile {
    .archive{

        &__header-wrapper{
            overflow: visible;
            padding: 60px 0 30px 0;
        }

        &__project-wrapper{
            margin: 0 30px 20px 30px;
        }

        &__category{
            &.header{
                display: block;
                margin-bottom: 15px;
            }
        }
        &__filter-button{
            min-height: 40px;
            height: auto;
        }
    }

}