@import '../../styles/breakpoints.scss';

.project-title{

    &__wrapper{
        position: fixed;
        top: 15px;
        width: 60%;
        left: 20%;
        text-align: center;
        z-index: 10;
    }
}

@include mobile{

    .project-title{

        &__wrapper{
            position: fixed;
            top: 0px;
            width: calc( 100% - 60px);
            left: 0;
            padding: 60px 30px 30px 30px;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 1) 50%,
              rgba(0, 0, 0, 0) 100%
            );
        }
    }
}