@import "../../styles/breakpoints.scss";

.header{

    &__jon,
    &__about,
    &__shop,
    &__work,
    &__archive{
        position: fixed;
        margin: 25px;
        z-index: 100;
        &.active{
            pointer-events: none;
            &:before{
                content: "[";
                position: absolute;
                top: 0;
                left: -5px;
            }
            &:after{
                content: "]";
                position: absolute;
                top: 0;
                right: -5px;
            }
        }
    }
    &__jon{
        top: 0;
        left: 0;
        &.active{
            pointer-events: none;
            &:before{
                content: "";
            }
            &:after{
                content: "";
            }
        }
    }
    &__about{
        top: 0;
        right: 0;
    }
    &__shop{
        bottom: 0;
        right: 0;
    }
    &__work{
        bottom: 0;
        left: 50%;
        margin: 25px 0;
        transform: translateX(-50%);
    }
    &__archive{
        bottom: 0;
        left: 0;
    }
}

@include mobile{

    .header{

        &__menu-button{
            padding: 25px;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 100;
        }
        &__menu-wrapper{
            width: calc(100% - 60px);
            height: calc(100vh - 60px);
            padding: 30px;
            background-color: #000;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 99;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.8s ease-in-out;
            &.close{
                opacity: 1;
                pointer-events: all;
            }
        }

        &__menu-item{
            position: relative;
            margin: 10px 0;
            padding: 0 30px;
            &.active{
                pointer-events: none;
                &:before{
                    content: "–";
                    position: absolute;
                    top: -3px;
                    left: -5px;
                }
            }
        }
    }

}