@import "../../styles/helpers.scss";
@import "../../styles/breakpoints.scss";

.related-projects {
  &__title {
    width: 100%;
    text-align: center;
    position: sticky;
    left: 0;
    margin-bottom: 30px;
  }
  &__container {
    width: 100%;
    height: calc(50vh - 90px);
    margin-top: 150px;
    padding: 30px 0 90px 0;
    position: relative;
    z-index: 10;
    background-color: #000;
  }

  &__items-wrapper {
    height: 100%;
    width: calc(100% - 90px);
    padding: 0 45px;
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &.centered{
      justify-content: center;
    }
  }
  &__item-wrapper {
    height: 100%;
    width: 25vw;
    flex: none;
    padding: 0 20px;
    // margin: 0 20px;
    position: relative;
    overflow-y: visible;
    @include hover {
      .related-projects__title-wrapper {
        opacity: 1;
      }
    }
  }
  &__media-wrapper {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    img,
    video {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }
  &__title-wrapper {
    padding-top: 10px;
    position: absolute;
    top: calc(100% - 40px);
    left: 0;
    // left: 10px;
    // width: 100%;
    width: calc(100% - 20px);
    height: auto;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
}

@include mobile{
  .related-projects {
    &__title {
      width: calc(100% - 60px);
      margin: 0 30px 30px 30px;
    }
    &__container {
      height: 25vh;
      margin-top: 50px;
      padding: 30px 0 150px 0;
    }
    &__items-wrapper {
      width: calc(100% - 60px);
      padding: 0 30px;
    }

    &__item-wrapper {
      width: 25vh;
    }
    &__media-wrapper {
      left: 20px;
    }
    &__title-wrapper {
      opacity: 1;
      padding-top: 20px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
    }
  }
}