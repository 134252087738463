@import "../../../styles/helpers.scss";
@import "../../../styles/breakpoints.scss";

.home {
//   &__regular-view-wrapper {
//     //     // height: auto;
//         max-height: 2000vh;
//         transition: all 0.8s ease-in-out;
//     //     background-color: red;
//     &.alt {
//     //   height: 100vh;
//       max-height: 100vh;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-evenly;
//       background-color: red;
//       .home__project-wrapper {
//         height: auto;
//         width: 100%;
//         margin: 0;
//       }
//       .home__project-media {
//         height: 100%;
//         width: auto;
//         margin: 0 auto;
//       }
//     }
//   }
  &__button {
    display: block;
    padding: 25px 50px 20px 50px;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 50vh;
    left: 50%;
    top: 50%;
    height: 2.1vw;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    @include hover {
      background-color: #fff;
      color: #000;
      box-shadow: 0 0 10px #fff;
    }
  }

  &__explore-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s ease-in-out;
    z-index: 11;
    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }
}

@include mobile {
  .home {
    &__button {
      height: 24px;
      position: static;
      margin: 70px auto 120px auto;
      width: calc(100% - 120px);
      left: auto;
      transform: none;
      padding: 10px 20px;
    }

    &__explore-wrapper {
      height: 50vh;
      position: static;
      opacity: 1;
      pointer-events: all;
    }
  }
}
