@import '../../styles/breakpoints.scss';

.info-wrapper{

    &__wrapper{
        position: fixed;
        bottom: 15px;
        height: 14px;
        width: 30%;
        left: 35%;
        padding: 15px 0 15px 15px;
        border-top: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
        border-radius: 10px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        transition: all 0.4s ease-in-out;
        z-index: 20;
        &.open{
            // height: calc(100vh - 97px);
            height:calc(100vh - 8vw - 15px);
            border: 1px solid #FFF;
        }
        &.absolute{
            position: absolute;
        }
        &.static{
            position: static;
        }
    }

    &__button{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 10px;
        text-align: center;
        &::before,
        &::after{
            position: absolute;
            top: 0;
        }
        &::before{
            content: "[";
            left: -5px;
        }
        &::after{
            content: "]";
            right: -5px;
        }
    }

    &__content-wrapper{
        margin: 30px;
        max-height: calc(100% - 60px);
        opacity: 0;
        pointer-events: none;
        transition-delay: 0.4s;
        transition: opacity 0.4s ease-in-out;
        padding-right: 15px;
        overflow-y: auto;
        line-height: 16px;
        &.open{
            opacity: 1;
            transition-delay: 0.4s;
            pointer-events: all;
        }
        p{
            margin-bottom: 15px;
        }
        strong{
            font-weight: bold;
        }
        em{
            font-style: italic;
        }
    }

    &__credit-wrapper{
        margin: 30px 0;
    }

}

@include mobile{

    .info-wrapper{

        &__wrapper{
            bottom: 50px;
            width: calc(100% - 60px);
            left: 22.5px;
            &.open{
                height: calc(50vh);
                border: 1px solid #FFF;
            }
            &.absolute{
                position: absolute;
            }
        }

    }
}