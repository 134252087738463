.project-scrollbar {

  &__total {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 30px;
    &:after {
        content: "";
        width: 15px;
        border-top: 1px solid #FFF;
        position: fixed;
        top: calc(50% - 0.5px);
        right: 53px;
        z-index: 10;
      }
  }

  &__index-wrapper {
    position: fixed;
    top: calc(50% - 50px);
    height: 100px;
    overflow: hidden;
    pointer-events: none;
    right: 38px;
    &:before {
        content: "";
        width: 31px;
        height: 50px;
        position: fixed;
        top: calc(50% - 50px);
        right: 30px;
        z-index: 10;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      &:after {
        content: "";
        width: 31px;
        height: 50px;
        position: fixed;
        bottom: calc(50% - 50px);
        right: 30px;
        z-index: 10;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
  }

  &__index-border{
    position: fixed;
    width: 25px;
    height: 100px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    border-radius: 10px;
    top: calc(50% - 50px);
    right: 30px;
    z-index: 11;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    &.disabled{
      opacity: 0;
    }
  }

  &__index-divide {
    height: calc(10vh - 8.5px);
    text-align: right;
    line-height: 14px;
    &:first-child {
      margin-top: 43px;
    }
    &:last-child {
      margin-bottom: 43px;
    }
  }
  &__index-number {
    position: sticky;
    top: calc(50% - 7px);
  }
}
