.home{


    &__change-view{
        position: fixed;
        padding: 2px 10px 0 10px;
        margin: 0 10px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-radius: 30px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        z-index: 10;
    }


}