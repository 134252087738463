@font-face {
  font-family: "Waldenburg Schmal";
  src: url("../assets/fonts/Waldenburg-Schmal.eot");
  src: url("../assets/fonts/Waldenburg-Schmal.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Waldenburg-Schmal.woff2") format("woff2"),
    url("../assets/fonts/Waldenburg-Schmal.woff") format("woff"),
    url("../assets/fonts/Waldenburg-Schmal.ttf") format("truetype"),
    url("../assets/fonts/Waldenburg-Schmal.svg#Waldenburg-Schmal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Times New Roman MT Std";
  src: url("../assets/fonts/TimesNewRomanMTStd-Cond.eot");
  src: url("../assets/fonts/TimesNewRomanMTStd-Cond.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/TimesNewRomanMTStd-Cond.woff2") format("woff2"),
    url("../assets/fonts/TimesNewRomanMTStd-Cond.woff") format("woff"),
    url("../assets/fonts/TimesNewRomanMTStd-Cond.ttf") format("truetype"),
    url("../assets/fonts/TimesNewRomanMTStd-Cond.svg#TimesNewRomanMTStd-Cond")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Waldenburg Normal";
  src: url("../assets/fonts/Waldenburg-Normal.eot");
  src: url("../assets/fonts/Waldenburg-Normal.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Waldenburg-Normal.woff2") format("woff2"),
    url("../assets/fonts/Waldenburg-Normal.woff") format("woff"),
    url("../assets/fonts/Waldenburg-Normal.ttf") format("truetype"),
    url("../assets/fonts/Waldenburg-Normal.svg#Waldenburg-Normal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
.about__content-location,
.project__title-year {
  font-family: "Waldenburg Normal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 14px;
}
.about__link-link,
.about__content {
  font-family: "Waldenburg Schmal", sans-serif;
  font-size: 18px;
  line-height: 18px;
}

.about__content {
  font-size: 40px;
  line-height: 37px;
}

.about__content,
.about__content-year,
.archive__project-year,
.archive__project-title,
.archive__project-client{
  font-family: "Times New Roman MT Std", serif;
  font-size: 3.8vw;
  line-height: 3.1vw;
}
.project-title__wrapper,
.related-projects__title,
.error__cta-wrapper,
.home__button,
.archive__filter-button,
.archive__filter-button.active,
.header__menu-item,
.home__mobile-title {
  font-family: "Times New Roman MT Std", serif;
  font-size: 3vw;
  line-height: 2.1vw;
}
.archive__filter-button.active{
  font-size: 3vw;
}
.about__content-year,
.archive__project-year,
.archive__filter-button {
  font-size: 24px;
  line-height: 28px;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    color: inherit;
  }
}

h2 {
  font-size: 24px;
  line-height: 28px;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

@include mobile {
  .about__content,
  .about__content-year,
  .archive__project-year,
  .archive__project-title,
  .archive__project-client {
    font-size: 30px;
    line-height: 24px;
  }
  .archive__project-year{
    font-size: 14px;
    line-height: 14px;
  }

  .project-title__wrapper,
  .related-projects__title,
  .error__cta-wrapper,
  .home__button,
  .archive__filter-button,
  .header__menu-item,
  .home__mobile-title {
    font-size: 30px;
    line-height: 24px;
  }

  .archive__filter-button.active,
  .header__menu-item {
    font-size: 40px;
  }

  .about__link-link{
    font-size: 16px;
    line-height: 18px;
  }

  .home__button{
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
