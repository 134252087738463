*::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000;
}
*::-moz-selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000;
}

*::-webkit-scrollbar {
  // width: 3px;
  width: 0;
}
*::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background: #000;
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
}

.error{
  &__cta-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__button{
    padding: 20px 50px;
    border: 1px solid #fff;
    border-radius: 50px;
  }
}

.home{
  // &__cta-wrapper{
  //   width: 100%;
  //   height: auto;
  //   padding-bottom: 45vh;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   text-align: center;
  // }
  // &__button{
  //   display: inline-block;
  //   padding: 20px 50px;
  //   border: 1px solid #fff;
  //   border-radius: 50px;
  // }
}
