@import "../../../styles/helpers.scss";
@import "../../../styles/breakpoints.scss";

.home {
  &__change-view {
    position: fixed;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    z-index: 10;
  }

  &__first-project-wrapper{
    width: 100%;
    // height: calc(100vh - 90px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__project-wrapper {
    width: calc(100% - 120px);
    height: 200vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    margin: 90px 0 90px 90px;
    padding-right: 30px;
    img,
    video {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
    &:first-child {
      height: 150vh;
      margin-top: 0;
      justify-content: flex-start;
    }
  }

  &__project-media {
    // display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 180px);
    position: relative;
  }

  &__mobile-project-title {
    display: none;
  }
}

@include mobile {
  .home {
    &__project-wrapper {
      width: calc(100% - 60px);
      height: auto;
      margin: 30px 30px 50px 30px;
      &:first-child {
        height: auto;
        justify-content: center;
        margin-top: 90px;
      }
    }

    &__project-media {
      height: auto;
    }
    &__mobile-project-title {
      display: block;
      margin-top: 10px;
      text-align: left;
    }
    &__category {
      margin: 0 5px;
    }
  }
}
