@import '../../styles/breakpoints.scss';

.project-media {

  &__container {
    padding: 90px 0 45px 0;
    position: relative;
  }

  &__wrapper {
    height: calc(100vh - 180px);
    width: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 90px 20px 90px;
    &.left{
      .project-media__image {
        align-self:flex-start;
        margin: 0;
      }
    }
    &.right{
      .project-media__image {
        align-self:flex-end;
        margin: 0;
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &__caption-wrapper{
    // min-height: 14px;
    padding: 0 90px 90px 90px;
    text-align: center;
    &.left{
      text-align: left;
    }
    &.right{
      text-align: right;
    }
  }
}

@include mobile {

  .project-media {

    &__container {
      padding: 100px 0 45px 0;
      position: relative;
    }

    &__wrapper {
      height: auto;
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 50px 30px 10px 30px;
      &.left{
        .project-media__image {
          align-self:auto;
          margin: 50px 0 10px 0;
        }
      }
      &.right{
        .project-media__image {
          align-self:auto;
          margin: 50px 0 10px 0;
        }
      }
    }

    &__image {
      margin: 0 auto;
      max-width: none;
      max-height: none;
      width: 100%;
      height: auto;
    }

    &__caption-wrapper{
      padding: 0 30px 50px 30px;
      text-align: center;
      &.left{
        text-align: left;
      }
      &.right{
        text-align: right;
      }
      &:last-child{
        margin-bottom: 100px;
      }
    }

  }

}