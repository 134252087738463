@import '../../styles/breakpoints.scss';


.about {
  &__header-wrapper {
    padding: 15px 200px 20px 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // flex-wrap: wrap;
    // position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 400px);
    // width: 100%;
    // background-color: red;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 3;
  }

  &__header-column-wrapper {
    margin: 0 15px 0 15px;
    // width: calc(33.33% - 30px);
    width: 50%;
    // flex: 1;
    // background-color: green;
    clear: both;
  }

  &__link-header {
    width: 50%;
    display: inline-block;
    text-align: right;
    text-transform: lowercase;
    // background-color: blue;
    // float: left;
  }

  &__link-link {
    width: 50%;
    display: inline-block;
    text-transform: uppercase;
    // float: left;
  }

  &__brackets-wrapper{
    position: absolute;
    top: 100%;
    // bottom: 15px;
    width: 20vw;
    left: calc(40vw - 1px);
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
    height: 14px;
    border-radius: 28px;
  }

  &__content-wrapper {
    padding: 110px 10%;
    min-height: calc(100vh - 220px);
    text-align: center;
  }

  &__content-title{
    position: sticky;
    top: 107px;
    height: 14px;
    padding-bottom: 30px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__content{
    width: 100%;
    min-height: calc(100vh - 300px);
        display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content-event {
      margin-bottom: 30px;
  }
}

@include about{

  .about {
    &__header-wrapper {
      padding: 60px 15px 20px 15px;
      display: block;
      position: static;
      top: auto;
      width: calc(100% - 30px);
    }
    &__header-column-wrapper {
      margin: 0 15px 15px 15px;
      width: calc(100% - 30px);
    }

    &__link-header {
      width: 30%;
      text-align: left;
      margin-bottom: 5px;
    }
  
    &__link-link {

      width: calc(70% - 10px);
      margin: 0 0 5px 10px;
    }

    &__brackets-wrapper{
     display: none;
    }

    &__content-title{
      position: sticky;
      top: 0;
      height: 14px;
      padding: 50px 0 30px 0;
      &::before{
        content: '(';
        position: absolute;
        left: 0;
      }
      &::after{
        content: ')';
        position: absolute;
        right: 0;
      }
    }

    &__content-wrapper {
      padding: 0 30px 50px 30px;
    }

  }

}