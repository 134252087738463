@import "../../../styles/breakpoints.scss";

.scrollbar {
  &__wrapper {
    width: 15px;
    height: 50vh;
    position: fixed;
    top: 25vh;
    left: 19px;
    overflow: hidden;
    display: block;
    &:before {
      content: "";
      width: 31px;
      height: 50px;
      position: fixed;
      top: 25vh;
      left: 15px;
      z-index: 10;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &:after {
      content: "";
      width: 31px;
      height: 50px;
      position: fixed;
      bottom: 25vh;
      left: 15px;
      z-index: 10;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__vertical {
    width: 1px;
    height: 50vh;
    position: fixed;
    top: 25vh;
    left: 26px;
    background-color: #fff;
  }

  &__divide {
    width: 100%;
    height: 20vh;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 12px;
    overflow: hidden;
    &:nth-child(2) {
      margin-top: 25vh;
      height: 15vh;
    }
    &:last-child {
      margin-bottom: 50vh;
      height: 15vh;
    }
  }

  &__index-wrapper {
    width: 10px;
    height: 50vh;
    position: fixed;
    top: 25vh;
    left: 48px;
    overflow-y: hidden;
    display: block;
    &:before {
      content: "";
      width: 10px;
      height: 20vh;
      position: fixed;
      top: 25vh;
      left: 48px;
      z-index: 10;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &:after {
      content: "";
      width: 10px;
      height: 20vh;
      position: fixed;
      bottom: 25vh;
      left: 48px;
      z-index: 10;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__index-divide {
    width: 100%;
    height: calc(20vh + 2px);
    overflow-x: visible;
    &:first-child {
      margin-top: calc(25vh - 7px);
      height: calc(15vh + 2px);
    }
    &:last-child {
      margin-bottom: 25vh;
      height: calc(15vh + 20px);
    }
  }
  &__index-number {
    position: sticky;
    // top: 50%;
    top: calc(50% - 7px);
  }

  &__total-number{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
  }

  &__title-wrapper{
    position: fixed;
    top: calc(50% - 50px);
    left: 90px;
    width: calc(100vw - 100px);
    height: 100px;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;
    
  }

  &__title-divide{
    width: 100%;
    height: calc(20vh + 2px);
    display: block;
    &:first-child {
      height: calc(15vh + 2px);
      margin-top: 43px;
    }
    &:last-child {
      margin-bottom:43px;
      height: calc(15vh + 2px);
    }
  }

  &__title{
    position: sticky;
    top: 43px;
  }



}

.marker {
  position: fixed;
  top: calc(50% - 0.5px);
  border-top: 1px solid #fff;
  left: 50px;
  width: 50px;
}

@include mobile{

  .scrollbar {
    &__wrapper {
      top: calc(75vh - 75px);
      left: 15px;
      &:before {
        top: calc(75vh - 75px);
      }
      &:after {
        bottom: 0;
      }
    }
    &__vertical {
      top: calc(75vh - 75px);
      left: 22px;
    }
    &__divide {
      &:nth-child(2) {
        height: 20vh;
      }
      &:last-child {
        height: calc(20vh + 20px);
      }
    }
    &__index-wrapper {
      top: calc(75vh - 75px);
      left: 40px;
      &:before {
      top: calc(75vh - 75px);
        left: 40px;
      }
      &:after {
        height: 50px;
        bottom: 0;
        left: 40px;
      }
    }
    &__total-number{
      top: auto;
      bottom: 68px;
      transform: none;
      left: 55px;
      z-index: 10;
    }
    &__title-wrapper{
      top: auto;
      bottom: 25px;
      left: 85px;
      width: calc(100vw - 95px);
    }

    &__title-divide{
      height: calc(20vh + 2px);
      &:first-child {
        height: calc(20vh + 2px);
      }
      &:last-child {
        height: calc(20vh + 2px);
      }
    }

    &__index-divide {
      &:first-child {
        height: calc(20vh + 2px);
      }
      &:last-child {
        height: calc(20vh + 50px);
      }
    }
  }

}